<template>
  <div>
    <TopNav/>
    <el-row class="box">
      <el-col :span="22" :offset="1" class="ecologyb">
        <h6 class="title">国家信创战略</h6>
        <p>信创产业作为战略性新兴产业，国家不断出台相关政策对行业的发展进行支持。政策扶持对于信创产业发展推进的意义重大，我国信创产业竞争力不断突破，国产化进程稳步推进。2019年我国提出发展信创产业，随后出台了一系列支持政策，2020年作为信创发展元年，国家一连颁布五项政策对信创产业发展规划提出相关规定。2022年开始政策重点提及“数字经济”、“数字政府”和国家信息化。</p>
        <p>《“十四五”国家信息化规划》中做出了指标要求，总体发展水平要求数字中国发展指数从2020年的85提升到2025年的95；数字设施、创新能力、产业转型中跟信创产业相关的指标有每万人口新一代信息技术产业发明专利拥有量、IT项目投资占全社会固定资产投资总额的比例、计算机、通信和其他电子设备制造业研发经费投入强度、信息消费规模等。</p>
        <img src="@/assets/ecology/g1.png" />
      </el-col>
      <el-col :span="22" :offset="1" class="ecologyb">
        <h6 class="title">信创市场机遇</h6>
        <p>在全球产业从工业化向数字化升级的关键时期，中国明确提出“数字中国”建设战略，以抢占下一时期的技术优先权。但2018年以来，受“华为、中兴事件”影响，我国科技尤其是上游核心技术受制于人的现状对我国经济持续高质量发展提出了严峻考验，为了摆脱这一现状，国家将信创产业纳入国家战略，提出“2+8”发展体系，2020-2022年中国IT产业在基础硬件、基础软件、行业应用软件、信息安全等诸多领域迎来了黄金发展期。</p>
        <p>据《中国信创产业发展白皮书(2021)》显示，到2023年，全球计算产业市场空间1.14万亿美元，中国计算产业市场空间接近全球的10%。按照50%为信创产业市场规模计算，2023年中国信创产业市场规模将突破3650亿元，市场容量将突破万亿。</p>
        <img src="@/assets/ecology/g2.png" />
      </el-col>

      <el-col :span="22" :offset="1" class="ecologyd">
        <h6 class="title">信创变革挑战</h6>
        <img src="@/assets/ecology/g3.png" />
      </el-col>

      <el-col :span="22" :offset="1" class="ecologyd">
        <h6 class="title">磐石信创融合解决方案</h6>
        <p>磐石信创融合平台（磐石云裳）积极响应国家信创战略，为企事业单位提供了从终端到应用到平台的全栈解决方案，通过全面技术契合以及应用、基础设施的全方位适配，支持多类型操作系统同时发布和统一管理，实现用户业务系统和关键应用的平滑迁移，帮助用户构建完整信创体系，在保证业务系统正常运转同时大大提高了业务平台的可靠性和生产数据的安全性，同时有效降低了运维成本。</p>
        <img src="@/assets/ecology/e9.png" />
      </el-col>


      <el-col :span="22" :offset="1" class="ecologyd">
        <h6 class="title">磐石信创融合产品线</h6>
        <p>历经数次产品迭代，磐石已完成以云桌面产品解决方案为主，包含云终端、终端管理系统、高价值应用上云、一体机、安全网关、以及Hyper-X等功能组件在内的完整产品布局。</p>
        <p>其中云终端产品全面覆盖x86、ARM等主流芯片技术，累计推出八个型号十六款标准化产品，并完成与国产主流操作系统的全面适配；一体机产品通过构建轻量级基础计算服务平台，面向工作组级用户提供迅捷交付服务，用户开箱即用；安全网关产品则面向互联网用户及跨域集团客户提供可靠的安全接入服务，极大延伸了云桌面解决方案应用场景，使得“随时桌面”成为可能。</p>
        <p>磐石更加重视行业用户需求并关注行业细分市场， 面向高端设计类应用用户推出了“应用上云”云服务解决方案，合理优化用户设计类应用资源，打造高价值应用资源池，系统支持对资源有效利用情况实时跟踪并及时分析，支持资源主动回收，有效保护用户投资。</p>
        <p>磐石以其完整的产品形态、卓越的产品性能、全行业细分市场解决方案的全面响应以及面向高价值设计应用市场应用服务的全力打造，为良好市场预期奠定了坚实基础。</p>
        <img src="@/assets/ecology/e10.png" />
      </el-col>

    </el-row>
    <FooterMobile/>
  </div>
</template>

<script>
import TopNav from '../../components/topNav.vue';
import FooterMobile from '../../components/footerMobile.vue';
export default {
    name: "EcologyMobile",
    components: { TopNav, FooterMobile },
    mounted(){
      setTimeout(() => {
        $("body,html").animate({scrollTop: 0},500);
      }, 500);
    }
}
</script>

<style>
  .clearfix{clear: both;}
  .box{padding-top: 12%;}
  .title{font-weight: normal;font-size: 4.4vw;margin: 15px 0px;border-left: 5px solid #E32121;padding-left: 10px;}
  .ecologyb p{font-size: 3.5vw;color: #888;text-indent: 2em;margin-top: 5px;margin-bottom: 5px;}
  .ecologyb img{width: 100%;margin: 5px 0;}
  .ecologyc{padding: 3vw;border: 1px solid #999;margin: 2vw 0;}
  .ecologyc img{width: 20%;display: block;margin: 0 auto;}
  .ecologyc h6{font-size: 4vw;text-align: center;margin: 2vw 0;}
  .ecologyc p{font-size: 3.5vw;color: #666;text-indent: 2em;}
  .ecologyd img{width: 100%;margin: 1vw 0;}
  .ecologyd p{font-size: 3.5vw;color: #666;text-indent: 2em;}
  .cloc{margin-top: 20px;}
  .cloc > img{width: 100%;}
  .cloca{display: flex;}
  .clocb{width: 33.333%;}
  .clocba{width: 6px;height: 6px;border-radius: 3px;background: #888;float: left;margin-top: 6px;}
  .clocb p{text-indent: 0em; font-size: 3vw;color: #888;list-style: disc;float: left;width: calc(100% - 15px);margin-left: 4px;}
</style>